import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { cloneDeep } from "lodash-es"

import {
  Navigation,
  NavigationItem,
  NavigationHeading,
  NavigationMega,
  NavigationMegaColumn,
  NavigationMegaPhotos,
  NavigationMegaPhotosColumn,
  NavigationMegaPlaceholder,
} from "../navigation"
import AssetWrapper from "../asset-wrapper"
import renderNavigationItem from "../../../utils/navigation-item"
import { useStore } from "../../../context/NewStoreContext"

const MegaItems = ({ items = [], assets = [], locale, closeMegaMenu, region }) => {

  return (
    <>
      {items?.map((column, index) => {
        if (
          !column?.items?.some(
            (item) =>
              item.reference?.internal?.type === "ContentfulAssetWrapperOld" || item.reference?.internal?.type === "ContentfulImage"
          )
        ) {
          return (
            <NavigationMegaColumn key={`mega-column-${index}`} size={2}>
              <Navigation key={`navigation-${index}`} isVertical>
                {column.items?.map((navigationItem, index) => {
                  if (navigationItem.linkTo === "/product/gift-card" && region?.name === "South Korea") {
                    return null
                  }
                  return navigationItem.isHeading ? (
                    <NavigationHeading
                      key={`navigation-heading-${index}`}
                      onClick={closeMegaMenu}
                    >
                      {renderNavigationItem({
                        name: navigationItem.name,
                        item: navigationItem,
                        locale: locale,
                        menuType: "desktop",
                        highlightLabel: navigationItem.highlightLabel,
                        highlightColor: navigationItem.highlightColor,
                      })}
                    </NavigationHeading>
                  ) : (
                    <NavigationItem
                      key={`navigation-item-${index}`}
                      onClick={closeMegaMenu}
                    >
                      {renderNavigationItem({
                        name: navigationItem.name,
                        item: navigationItem,
                        locale: locale,
                        menuType: "desktop",
                        highlightLabel: navigationItem.highlightLabel,
                        highlightColor: navigationItem.highlightColor,
                      })}
                    </NavigationItem>
                  )
                })}
              </Navigation>
            </NavigationMegaColumn>
          )
        }
      })}

      {assets?.length ? (
        <NavigationMegaColumn size={assets.length * 4}>
          <NavigationMegaPhotos>
            {assets?.map((assetWrapperItem, index) => {
              return (
                <NavigationMegaPhotosColumn key={`photo-column-${index}`}>
                  <AssetWrapper
                    key={assetWrapperItem.reference.contentful_id}
                    asset={assetWrapperItem.reference?.asset}
                    navigationAssetWrapper={true}
                    linkTo={assetWrapperItem.linkTo}
                    imageText={assetWrapperItem.name}
                    {...assetWrapperItem.reference}
                  />
                </NavigationMegaPhotosColumn>
              )
            })}
          </NavigationMegaPhotos>
        </NavigationMegaColumn>
      ) : null}
    </>
  )
}

const DesktopMenu = ({ setMenuVisible }) => {
  const { cart } = useStore()
  const data = useStaticQuery(query)
  const megaMenuRef = React.useRef([])
  const [sameHeight, setSameHeight] = React.useState(0)
  const [megaMenuOptions, setMegaMenuOptions] = React.useState({
    items: [],
    assets: [],
  })
  const [isMegaMenuOpen, setIsMegaMenuOpen] = React.useState(false)

  const items = React.useMemo(() => {
    if (!data.main) {
      return
    }

    const translationSpecific = data.main?.nodes.find((node) => {
      const localeMatch = "en-US"
      return node.node_locale === localeMatch
    })?.items

    const items = cloneDeep(translationSpecific)
    return items || []
  }, [])

  const handleResize = () => {
    const height = Math.max.apply(
      Math,
      megaMenuRef.current.map((item) => item.clientHeight)
    )

    if (height > sameHeight) {
      setSameHeight(height)
    }
  }

  React.useEffect(() => {
    if (megaMenuRef.current) {
      window.addEventListener("resize", () => {
        setSameHeight(0)
        handleResize()
      })
      handleResize()
    }

    return () => window.removeEventListener("resize", handleResize)
  }, [megaMenuRef])

  return (
    <Navigation
      onMouseOver={() => {
        setMenuVisible(true)
        setIsMegaMenuOpen(true)
      }}
      onMouseLeave={() => {
        setMenuVisible(false)
        setIsMegaMenuOpen(false)
      }}
      isFullHeight
    >
      {items?.length &&
        items?.map((topLevelItem) => {
          let topLevelItemAssetWrapperItems = []

          topLevelItem.items?.map((column) => {
            const items = column.items?.filter((navItem) => {
              if (
                navItem.reference?.internal?.type === "ContentfulAssetWrapperOld" || navItem.reference?.internal?.type === "ContentfulImage"
              ) {
                return navItem
              }
            })

            if (items?.length) {
              topLevelItemAssetWrapperItems.push(items[0])
            }
          })

          return (
            <NavigationItem
              onMouseEnter={() =>
                setMegaMenuOptions({
                  items: topLevelItem.items,
                  assets: topLevelItemAssetWrapperItems,
                })
              }
              key={`top-level-${topLevelItem.id}`}
            >
              {renderNavigationItem({
                name: topLevelItem.title,
                item: topLevelItem,
                locale: "en-US",
                menuType: "desktop",
              })}

              {megaMenuOptions.items.length > 0 && (
                <NavigationMegaPlaceholder>
                  <NavigationMega
                    ref={(el) => el && megaMenuRef.current.push(el)}
                    key={`mega-${topLevelItem.id}`}
                    sameHeight={sameHeight}
                    isOpen={isMegaMenuOpen}
                  >
                    <MegaItems
                      items={topLevelItem.items}
                      assets={topLevelItemAssetWrapperItems}
                      locale={"en-US"}
                      closeMegaMenu={() => setIsMegaMenuOpen(false)}
                      region={cart?.region}
                    />
                  </NavigationMega>
                </NavigationMegaPlaceholder>
              )}
            </NavigationItem>
          )
        })}
      {megaMenuOptions?.items && (
        <NavigationMega sameHeight={sameHeight} isOpen={isMegaMenuOpen}>
          <MegaItems
            items={megaMenuOptions.items}
            assets={megaMenuOptions.assets}
            locale={"en-US"}
            closeMegaMenu={() => setIsMegaMenuOpen(false)}
            region={cart?.region}
          />
        </NavigationMega>
      )}
    </Navigation>
  )
}

const query = graphql`
  query {
    main: allContentfulNavigationMenu(
      filter: { name: { eq: "New Navigation Menu (Charlie / Kris)" } }
    ) {
      nodes {
        name
        node_locale
        items {
          ... on ContentfulNavigationItemTopLevelItem {
            id
            title
            linkTo
            items {
              title
              items {
                isHeading
                name
                linkTo
                highlightLabel
                highlightColor
                reference {
                  ...SimplePage
                  ...SimpleBlogPost
                  ...AssetWrapper
                  ... on ContentfulImage {
                    id
                    asset {
                      description
                      gatsbyImageData(layout: CONSTRAINED, quality: 75)
                      file {
                        url
                        contentType
                      }
                    }
                    showDescription
                    topMargin
                    bottomMargin
                    internal {
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default DesktopMenu
